import React, { useCallback } from 'react';
import { Box, FormHelperText } from '@mui/material';

import { MultiCheckboxProps } from './MultiCheckbox.types';
import useStyles from './MultiCheckbox.styles';
import { Typography, Checkbox } from '../index';
import { getOptionsWithTranslationsOrLabel } from '../utils/translationOptionsVisibility';

const MultiCheckbox = (props: MultiCheckboxProps) => {
	const {
		label,
		options,
		name,
		value,
		className,
		required,
		error,
		helperText,
		onChange,
		'data-test': dataTest
	} = props;
	const classes = useStyles(props);

	const handleChange = useCallback(
		(id: string) => {
			const newValue = value ? [...value] : [];
			const index = newValue.indexOf(id);

			if (index !== -1) {
				newValue.splice(index, 1);
			} else {
				newValue.push(id);
			}

			onChange(newValue);
		},
		[value, onChange]
	);

	return (
		<Box className={className} name={name}>
			<Typography
				variant="displayXSmall"
				title={label}
				className={classes.label}
				required={required}
				color={error ? 'error' : 'primary'}
			/>
			<Box className={classes.options}>
				{getOptionsWithTranslationsOrLabel(options).map(({ id, label, localeId }) => (
					<Checkbox
						checked={value?.includes(id) || false}
						onChange={() => handleChange(id)}
						label={label}
						labelLocaleId={localeId}
						className={classes.option}
						key={id}
						data-testid={`${dataTest}_${id}`}
					/>
				))}
			</Box>
			{helperText && <FormHelperText error>{helperText}</FormHelperText>}
		</Box>
	);
};

export default MultiCheckbox;
