import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { RadioGroup, RadioGroupProps } from '../RadioGroup';
import { FormikInputProps, FormikInputWrapper, useFormikLabels } from '..';

type Props = FormikInputProps<RadioGroupProps>;

/**
 * Use this component for RadioGroups under a formik form.
 */
const FormikRadioGroup = (props: Props) => {
	const { formik, gridProps, gridClassName, name, intl, options, ...others } = props;
	const { messages } = intl;
	const { label, error, hasError, formikValue } = useFormikLabels(props, messages);

	return (
		<FormikInputWrapper gridProps={gridProps} className={gridClassName}>
			<RadioGroup
				name={name}
				label={label}
				value={formikValue}
				onChange={(event, value) => {
					formik.setFieldValue(name, value);
				}}
				options={options}
				error={hasError}
				helperText={hasError && <FormattedMessage {...error} />}
				onBlur={formik.handleBlur}
				{...others}
			/>
		</FormikInputWrapper>
	);
};

const EnhancedFormikTextField = injectIntl(FormikRadioGroup);

export { FormikRadioGroup };

export default EnhancedFormikTextField;
