import React from 'react';
import { TopUpCardProps } from './TopUpCard.types';
import useStyles from './TopUpCard.styles';
import { Box } from '@mui/material';
import classnames from 'classnames';
import {
	commonTracking,
	createURL,
	formatCurrency,
	formatPrice,
	getMembershipCreditNameForItem,
	useChangeURL
} from '../..';
import { Button, Typography } from 'components';
import { useIntl } from 'react-intl';

const TopUpCard = (props: TopUpCardProps) => {
	const { topUpItem, membership, className, eventId, index } = props;
	const { amount, price } = topUpItem;
	const classes = useStyles();
	const changeURL = useChangeURL();
	const { locale } = useIntl();
	const accreditationPointsName = getMembershipCreditNameForItem(topUpItem, locale);
	const accreditationPointsTitle = `+${amount} ${accreditationPointsName}`;
	const currencySymbol = formatCurrency(membership.currency || 'EUR');

	// for now, we are using the same `cme` part for or locales even if credit name of the country is the different one
	// It's easy to maintain translations this way
	const descriptionId = `common.top-ups.description.${amount}-cme`;

	const queryParams = new URLSearchParams();

	queryParams.append('membershipId', membership.id);
	if (eventId) {
		queryParams.append('eventId', eventId);
	}

	const handleSelectTopUp = () => {
		const buyLink = createURL(
			`/booking/top-ups/${topUpItem.id}?${queryParams.toString()}`,
			'catalog',
			true
		).url;
		commonTracking.topUpProductAddedToCart(topUpItem, locale, buyLink, index, membership);
		changeURL(buyLink, 'catalog', true);
	};

	return (
		<Box className={classnames(classes.container, className)}>
			<Box>
				<Typography
					title={accreditationPointsTitle}
					color="secondary"
					className={classes.pointsAmount}
				/>
				<Typography
					title={formatPrice(price || 0, currencySymbol)}
					color="primary"
					className={classes.price}
				/>
				<Typography
					localeId="common.top-ups.for-comparison"
					variant="textSmall"
					color="body"
					colorVariant="dark"
				/>
				<Typography
					localeId={descriptionId}
					variant="textSmall"
					color="body"
					colorVariant="dark"
				/>
			</Box>
			<Button
				onClick={handleSelectTopUp}
				localeId="common.top-ups.buy-top-up"
				fullWidth
				className={classes.button}
				variant="contained"
				size="large"
			/>
		</Box>
	);
};

export default TopUpCard;
