import React, { useContext, useEffect } from 'react';

import {
	Queries,
	redirectAnonymous,
	setStateInCookies,
	useChangeURL,
	USER_URLS,
	useRouter,
	commonTracking
} from '../';
import { Loader } from 'components';
import { useIntl } from 'react-intl';

export type AuthenticatedUser = Queries.GetAuthenticatedUserQuery['authenticatedUser'];

export type UserContextType =
	| {
			// default state
			user: null;
			isLoggedIn: false;
			isLoading: true;
			isError: false;
	  }
	| {
			// error state
			user: null;
			isLoggedIn: false;
			isLoading: false;
			isError: true;
	  }
	| {
			// successful state
			user: AuthenticatedUser;
			isLoggedIn: true;
			isLoading: false;
			isError: false;
	  };

const defaultUserContext = {
	user: null,
	isLoggedIn: false,
	isLoading: true,
	isError: false
} as const;

const UserContext = React.createContext<UserContextType>(defaultUserContext);
const useUserContext = () => useContext(UserContext);

type UserContextProviderProps = {
	children: React.ReactNode;
};

type Query = {
	loginRequired?: string | null | undefined;
};

const UserContextProvider = (props: UserContextProviderProps) => {
	const { children } = props;
	const { query, pathname } = useRouter<Query>();
	const changeURL = useChangeURL();
	const intl = useIntl();
	const { locale } = intl;
	const loginRequired = !!query.loginRequired;
	const {
		data: { authenticatedUser } = { authenticatedUser: null },
		error,
		loading
	} = Queries.useGetAuthenticatedUserQuery();

	const [getUser] = Queries.useGetUserForDashboardLazyQuery();
	const [getUserMembership] = Queries.useGetUserMembershipLazyQuery();

	let userContext: UserContextType = defaultUserContext;

	if (error || !loading) {
		if (loginRequired) {
			redirectAnonymous({ changeURL, locale });
		}
		userContext = {
			user: null,
			isLoggedIn: false,
			isLoading: false,
			isError: true
		};
	}

	if (!error && authenticatedUser) {
		if (
			(!authenticatedUser.firstName || !authenticatedUser.lastName) &&
			!authenticatedUser.roles?.includes('ADMIN') &&
			pathname !== '/user/complete-profile'
		) {
			const nonce = setStateInCookies({});
			changeURL(USER_URLS.completeProfile({ nonce }), 'catalog');
		}
		userContext = {
			user: authenticatedUser,
			isLoggedIn: true,
			isLoading: false,
			isError: false
		};
	}

	// call segment identify for each new login
	useEffect(() => {
		const localStorageKey = 'last-tracked-login';
		const lastTrackedLogin = localStorage.getItem(localStorageKey);
		const lastLogin = authenticatedUser?.lastLogin;

		if (!authenticatedUser || !lastLogin || lastTrackedLogin === lastLogin) {
			return;
		}

		(async () => {
			const userId = authenticatedUser.id;

			const [userData, userMembershipData] = await Promise.all([
				getUser({ variables: { userId } }),
				getUserMembership({ variables: { userId } })
			]);
			const user = userData.data?.user;
			const userMembership = userMembershipData.data?.userMembership || null;

			if (!user) {
				return;
			}

			commonTracking.identifyUser(userId, { user, userMembership, intl });
			commonTracking.trackLogin();
			localStorage.setItem(localStorageKey, lastLogin);
		})();
	}, [authenticatedUser]);

	return (
		<UserContext.Provider value={userContext}>
			{loginRequired && (loading || error) && <Loader fullScreen />}
			{children}
		</UserContext.Provider>
	);
};

export { UserContextProvider, useUserContext };
