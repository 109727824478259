import { CATALOG_URLS } from '../urls';
import buildURLWithParams from './buildURLWithParams';
import createURL from './createURL';

type Content = { contentId: string | null | undefined; slug?: string | null | undefined };

export const generateContentURL = (
	{ slug, contentId }: Content,
	redirectToProgram = false
): string => {
	const newURL = `${
		createURL(
			buildURLWithParams(CATALOG_URLS.content, [slug ?? contentId ?? '']),
			'catalog',
			true
		).url
	}${redirectToProgram ? '#program' : ''}`;
	return newURL;
};

export const generateOnDemandContentURL = ({ slug, contentId }: Content): string => {
	return createURL(
		buildURLWithParams(CATALOG_URLS.onDemandContent, [slug ?? contentId ?? '']),
		'catalog',
		true
	).url;
};

export const generateOnDemandCourseURL = ({ slug, contentId }: Content): string => {
	return createURL(
		buildURLWithParams(CATALOG_URLS.onDemandAccreditationCourse, [slug ?? contentId ?? '']),
		'catalog',
		true
	).url;
};
