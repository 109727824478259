import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	freeBadge: {
		position: 'absolute',
		top: theme.spacing(1),
		right: theme.spacing(1),
		'& > svg': {
			fill: theme.palette.primary.main
		}
	},
	// lecture - start
	playerContainer: {
		width: '100%',
		position: 'relative',
		pointerEvents: 'none',
		'& .react-player__shadow': {
			display: ['none', '!important']
		},
		...theme.border({ radius: 3, color: theme.palette.body.light })
	},
	duration: {
		backgroundColor: `${theme.palette.common.black}B2`,
		color: theme.palette.common.white,
		position: 'absolute',
		bottom: 6,
		right: theme.spacing(1),
		padding: '1px 3px',
		lineHeight: '14px',
		fontWeight: 'bold',
		fontSize: 12,
		...theme.border({ radius: 3, color: theme.palette.body.light })
	},
	fallbackContainer: {
		backgroundColor: `${theme.palette.secondary.main}33`,
		borderTopLeftRadius: theme.spacing(0.5),
		borderTopRightRadius: theme.spacing(0.5)
	},
	guidelineIcon: {
		width: 80,
		height: 80,
		color: theme.palette.secondary.main
	},
	// lecture and webinar - end
	// course - start
	imageContainer: {
		position: 'relative',
		height: 140,
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
		backgroundColor: theme.palette.secondary.dark
	},
	smallImageContainer: {
		...theme.border({ radius: 3, color: theme.palette.body.light })
	},
	image: {
		width: '100%',
		objectFit: 'cover',
		height: '100%',
		position: 'absolute',
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6
	},
	topic: {
		position: 'relative',
		width: 96,
		height: 96,
		top: theme.spacing(1),
		left: theme.spacing(0.5),
		color: theme.palette.secondary[400]
	},
	brandImage: {
		color: 'rgba(255, 255, 255, 0.7)'
	},
	brandName: {
		color: 'rgba(255, 255, 255, 0.7)'
	},
	titleContainer: {
		position: 'absolute',
		bottom: theme.spacing(0.5),
		left: theme.spacing(1),
		right: theme.spacing(1)
	},
	smallCourseContainerImage: {
		display: 'flex',
		justifyContent: 'center',
		height: '100%',
		alignItems: 'center'
	},
	smallCourseTopic: {
		left: 0,
		top: 0
	},
	title: {
		fontSize: 18,
		lineHeight: '1.25',
		color: theme.palette.common.white
	},
	badge: {
		position: 'absolute',
		top: 20,
		right: 30
	},
	// course - end

	// webinar - start
	webupContainer: {
		position: 'relative',
		height: '100%',
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
		backgroundColor: theme.palette.accent.main
	},
	webupContainerSmall: {
		height: 140
	},
	brandNameWebinar: {
		display: 'none'
	},
	brandImageWebinar: {
		color: theme.palette.label.main,
		width: 40,
		height: 40
	},
	webupImage: {
		minHeight: 140,
		height: '100%',
		width: '100%',
		borderTopLeftRadius: 3,
		borderTopRightRadius: 3,
		display: 'flex',
		justifyContent: 'center'
	}
	// webinar - end
}));

export default useStyles;
