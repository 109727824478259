import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	notificationPopover: {
		maxWidth: 168,
		background: theme.palette.primary.main,
		color: theme.palette.common.white,
		...theme.typography.caption,
		fontWeight: 400,
		textAlign: 'center',
		padding: theme.spacing(1)
	},
	warningTriangle: {
		width: 24,
		height: 24,
		marginRight: theme.spacing(0.5)
	},
	infoIcon: {
		color: theme.palette.primary[500]
	}
}));

export default useStyles;
