import React from 'react';

import { Box, Container, IconButton } from '@mui/material';
import { useIntl } from 'react-intl';
import HeaderUserNavBar from '../../HeaderUserNavBar/HeaderUserNavBar';
import { useUserContext, useMembershipContext } from '../../../contexts';
import HeaderLinks from '../HeaderLinks';
import { Logo, SearchBox, Icon, DrawerMenu, Link } from 'components';
import type { HeaderProps } from '..';
import useStyles from './Header.desktop.styles';
import { headerLinks, useHeaderSearch } from '../Header.utils';
import { booleanFilter, getCountryCode, isFeatureEnabled } from '../../../utils';
import LocaleSwitch from '../LocaleSwitch';
import useHeaderHeight from '../../../hooks/useHeaderHeight';
import { CATALOG_URLS, MEDIATHEK_URLS } from '../../../urls';
import NextLink from '../../NextLink';
import { useFlags } from '../../..';

export const HeaderDesktop = (props: HeaderProps) => {
	const { drawerItems, drawerProps } = props;
	const { isLoading, isLoggedIn, user } = useUserContext();
	const { userMembership } = useMembershipContext();
	const headerHeight = useHeaderHeight();
	const flags = useFlags();
	const { formatMessage, locale } = useIntl();
	const countryCode = getCountryCode(locale);
	const classes = useStyles();

	const { onClear, onSearch, onSearchChange, search } = useHeaderSearch();
	const isMediathekEnabled = isFeatureEnabled('mediathek', getCountryCode(locale));

	return (
		<DrawerMenu
			items={drawerItems ?? []}
			linkComponent={NextLink}
			classes={{
				...drawerProps?.classes,
				root: [classes.root, drawerProps?.classes?.root].filter(booleanFilter).join(' ')
			}}
			header={
				<Container
					style={{ height: headerHeight }}
					maxWidth="xl"
					className={classes.container}
				>
					<Box className={classes.topContainer}>
						<Box className={classes.logoWrapperContainer}>
							<Box className={classes.logo}>
								{/* This is a temporary fix, as CMED is not fully integrated to our platform and we need to redirect to drupal on click, the redirect is happening through ingress */}
								{locale === 'en-GB' ? (
									<Link className={classes.link} href="/">
										<Logo locale={locale} />
									</Link>
								) : (
									<NextLink className={classes.link} href="/">
										<Logo locale={locale} />
									</NextLink>
								)}
							</Box>
							{isMediathekEnabled ? (
								<>
									<div className={classes.sectionsMenuContainer}>
										<NextLink
											href={MEDIATHEK_URLS.topics}
											className={classes.topicsLink}
										>
											<Icon
												icon="listBullets"
												className={classes.topicsIcon}
											/>
											{formatMessage({ id: 'common.header.topics' })}
										</NextLink>
									</div>
									<SearchBox
										placeholder={formatMessage({
											id: 'common.header.search-placeholder'
										})}
										onChange={onSearchChange}
										value={search || ''}
										onEnter={onSearch}
										onClear={onClear}
										classes={{
											root: classes.searchBoxRoot,
											inputBaseRoot: classes.searchBox,
											notchedOutline: classes.searchBoxNotchedOutline
										}}
									/>
								</>
							) : (
								<Box className={classes.navbarContainer}>
									<HeaderLinks
										menuItems={headerLinks(countryCode, false, locale, flags)}
									/>
									<LocaleSwitch />
								</Box>
							)}
						</Box>
						<IconButton
							component={NextLink}
							className={classes.iconButton}
							href={CATALOG_URLS.contact}
							size="large"
						>
							<Icon icon="phone" className={classes.icon} />
						</IconButton>
						<IconButton
							component={NextLink}
							className={classes.iconButton}
							href={CATALOG_URLS.faq}
							size="large"
						>
							<Icon icon="help" className={classes.icon} />
						</IconButton>
						<Box className={classes.userInfoContainer}>
							<HeaderUserNavBar
								isLoggedIn={isLoggedIn}
								isLoading={isLoading}
								classes={{
									primaryButton: classes.primaryButton,
									link: classes.link,
									avatar: classes.avatar
								}}
								user={user}
								userMembership={userMembership}
							/>
						</Box>
					</Box>
					{isMediathekEnabled && (
						<Box className={classes.navbarContainer}>
							<HeaderLinks
								menuItems={headerLinks(countryCode, false, locale, flags)}
							/>
							<LocaleSwitch />
						</Box>
					)}
				</Container>
			}
			{...drawerProps}
		/>
	);
};
