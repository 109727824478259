import React from 'react';
import { Box } from '@mui/material';
import { HeaderLinksProps } from './HeaderLinks.types';
import { HeaderLink } from './HeaderLink';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	linksContainer: {
		display: 'flex',
		gap: 12,
		height: 48,
		alignItems: 'center',
		[theme.breakpoints.down('lg')]: {
			gap: 6
		}
	},
	separator: {
		width: 1,
		height: 16,
		backgroundColor: theme.palette.body.light
	}
}));

const flattenWithSeparator = (array: HeaderLinksProps['menuItems'], separatorText: string) => {
	return ([] as HeaderLinksProps['menuItems'][number]).concat(
		...array.map((innerArray, index) => {
			if (index > 0) {
				return [{ localeId: separatorText, href: '' }, ...innerArray];
			} else {
				return innerArray;
			}
		})
	);
};

const HeaderLinks = (props: HeaderLinksProps) => {
	const classes = useStyles();
	const { menuItems } = props;
	const flatMenuItems = flattenWithSeparator(menuItems, 'separator');

	return (
		<Box className={classes.linksContainer}>
			{flatMenuItems.map((menuItem, index) => {
				if (menuItem.localeId === 'separator') {
					return <div key={index} className={classes.separator} />;
				}

				return (
					<HeaderLink
						key={menuItem.localeId}
						href={menuItem.href}
						localeId={menuItem.localeId}
						icon={menuItem.iconUrl}
					/>
				);
			})}
		</Box>
	);
};

export default HeaderLinks;
