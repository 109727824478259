import makeStyles from '@mui/styles/makeStyles';

export const useTextFieldStyles = makeStyles(
	(theme) => ({
		root: {
			flex: 1,
			marginRight: theme.spacing(3)
		},
		input: {
			padding: 10,
			'&::placeholder': {
				textOverflow: 'ellipsis !important',
				color: theme.palette.label.main
			},
			'&:not(:focus)': {
				'&::placeholder': {
					visibility: 'unset'
				}
			}
		},
		notchedOutline: {
			...theme.border({ radius: 6, color: '#F5F5F5', width: 2 })
		},
		icon: {
			color: theme.palette.primary[500]
		},
		startIcon: {
			color: theme.palette.secondary.main
		},
		iconFocused: {
			color: theme.palette.primary.main
		},
		closeIcon: {
			color: '#14142B'
		},
		inputBaseRoot: {
			height: 44,
			width: '100%',
			'&.Mui-disabled': {
				color: theme.palette.titleActive.main,
				pointerEvents: 'none',
				background: theme.palette.background.default,

				'& $notchedOutline': {
					borderColor: '#F5F5F5'
				}
			},
			'&:active': {
				backgroundColor: theme.palette.common.white
			},
			'&:hover': {
				backgroundColor: '#F5F5F5',

				'& $notchedOutline': {
					borderColor: '#F5F5F5'
				}
			},

			'&.Mui-hover': {
				backgroundColor: '#F5F5F5'
			},

			'&.Mui-focused': {
				backgroundColor: theme.palette.common.white,

				'& $notchedOutline': {
					borderColor: '#F5F5F5',
					borderWidth: 1
				}
			},

			'&.Mui-error': {
				'&, &:hover, &.Mui-focused': {
					'& $notchedOutline': {
						borderColor: '#F5F5F5'
					}
				}
			},
			[theme.breakpoints.up('sm')]: {
				maxWidth: 430
			}
		}
	}),
	// to have more priority over TextField styles
	{ name: 'SearchBox', index: 10 }
);

const useStyles = () => ({});

export default useStyles;
