import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		textFieldOutlined: {
			border: 'unset'
		},
		notchedOutline: {
			borderColor: theme.palette.line.main
		},
		outlined: {
			backgroundColor: theme.palette.common.white,
			color: theme.palette.body.dark
		},
		selected: {
			backgroundColor: theme.palette.secondary['100'],
			color: theme.palette.common.white,
			'&$text': {
				color: theme.palette.secondary.main
			}
		},
		sectionBorder: {
			margin: theme.spacing(0, 0.5),
			...theme.border({ radius: 0, color: theme.palette.body.light, kind: 'bottom' })
		},
		label: {
			color: theme.palette.body.dark,
			top: -15,
			height: 34,
			display: 'flex',
			alignItems: 'center',
			'& > p': {
				fontWeight: 400
			}
		},
		selectedLabel: {
			color: theme.palette.secondary.main
		},
		iconSelected: {
			right: 10,
			color: theme.palette.secondary.main
		},
		icon: {
			right: 10,
			color: theme.palette.common.black,
			opacity: 0.38
		},
		disabled: {},
		filled: {},
		iconFilled: {},
		iconOpen: {},
		iconOutlined: {},
		root: {},
		select: {},
		selectMenu: {},
		text: {
			lineHeight: 1,
			color: theme.palette.common.black,
			opacity: 0.6
		},
		minimalText: {
			color: theme.palette.body.dark,
			...theme.typography.body1
		},
		minimalTextField: {
			color: theme.palette.common.black,
			paddingRight: theme.spacing(4),
			backgroundColor: 'transparent',
			' & :hover': {
				backgroundColor: 'transparent'
			}
		},
		iconMinimal: {
			color: theme.palette.body.dark,
			opacity: 0.38
		},
		inputBaseRoot: {
			height: 36,
			minWidth: 200,
			'& > $select$input': {
				paddingRight: theme.spacing(5)
			}
		},
		input: {
			padding: 0,
			paddingRight: 35
		}
	}),
	// to override text field when some value is selected
	{ name: 'Dropdown', index: 10 }
);

export default useStyles;
