import { DrawerContentProps } from './DrawerMenu.types';
import { IconButton, List, ListItem, ListItemIcon } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { isMenuLinkItem } from './utils';
import { Link } from '../Link';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

const DrawerContent = (props: DrawerContentProps) => {
	const {
		handleDrawerToggle,
		linkComponent,
		items,
		currentPath,
		classes,
		toolbarContent = null,
		menuItemsHeader = null,
		menuItemsFooter = null,
		hideDrawerIconOnOpen = false
	} = props;
	return (
		<>
			<div className={classes.toolbar}>
				{!hideDrawerIconOnOpen && (
					<IconButton
						color="inherit"
						edge="start"
						onClick={handleDrawerToggle}
						size="large"
					>
						<Icon icon="menu" className={classes.menuIcon} />
					</IconButton>
				)}
				{toolbarContent}
			</div>
			{menuItemsHeader}
			{items.map((sectionItems, index) => (
				<List className={classes.list} key={index}>
					{sectionItems.map((item) => {
						if (!isMenuLinkItem(item)) {
							return item;
						}

						const isActive = item.exact
							? currentPath === item.href
							: item.href && currentPath
								? currentPath.includes(item.href)
								: false;

						return (
							<ListItem
								className={classNames(classes.listItem, {
									[classes.activeItem]: isActive
								})}
								key={item.localeId || item.text}
							>
								<Link
									href={item.href}
									component={linkComponent}
									className={classes.link}
								>
									{item.iconName && (
										<ListItemIcon className={classes.listItemIcon}>
											<Icon
												icon={item.iconName}
												className={classes.linkIcon}
											/>
										</ListItemIcon>
									)}
									<Typography
										localeId={item.localeId}
										title={item.text}
										variant="linkSmall"
									/>
								</Link>
							</ListItem>
						);
					})}
				</List>
			))}
			{menuItemsFooter}
		</>
	);
};
export default DrawerContent;
