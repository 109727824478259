import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import FormikInputWrapper from '../FormikInputWrapper/FormikInputWrapper';
import { MultiCheckbox, MultiCheckboxProps } from '../MultiCheckbox';
import { FormikInputProps, isRequiredField, useFormikLabels } from '../';

type Props = FormikInputProps<MultiCheckboxProps>;

/**
 * Use this component for Multiple Checkboxs under a formik form.
 */
const FormikMultiCheckbox = (props: Props) => {
	const { formik, gridProps, gridClassName, name, intl, ...others } = props;
	const { messages } = intl;
	const { label, error, hasError, formikValue } = useFormikLabels(props, messages);

	if (typeof formikValue !== 'undefined' && !Array.isArray(formikValue)) {
		throw new Error(`Field ${name} is not an array`);
	}

	if (Array.isArray(formikValue) && !formikValue.every((item) => typeof item === 'string')) {
		throw new Error(`Field ${name} is not an array of strings`);
	}

	const value = !formikValue ? [] : (formikValue as Array<string>);

	return (
		<FormikInputWrapper gridProps={gridProps} className={gridClassName}>
			<MultiCheckbox
				name={name}
				label={label}
				required={isRequiredField(formik.validationSchema, name)}
				value={value}
				onChange={(value) => {
					formik.setFieldTouched(name, true);
					formik.setFieldValue(name, value, true);
				}}
				error={hasError}
				helperText={hasError && <FormattedMessage {...error} />}
				{...others}
			/>
		</FormikInputWrapper>
	);
};

const EnhancedFormikMultiCheckbox = injectIntl(FormikMultiCheckbox);

export { FormikMultiCheckbox };

export default EnhancedFormikMultiCheckbox;
