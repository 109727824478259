import React, { useRef, useState } from 'react';
import { useUserContext } from '../../../contexts';
import { Box, DialogContent, DialogTitle, Popover } from '@mui/material';
import useStyles from './AddonsPopover.styles';
import { Types, Queries, BrandIconOnly, getAddonsQueryVariables } from '../../../';
import classnames from 'classnames';
import AddonsPopoverContent from './AddonsPopoverContent';
import { Dialog, Typography } from 'components';

type Props = {
	className?: string;
	classes?: Partial<ReturnType<typeof useStyles>>;
	isDesktop: boolean;
};

const AddonsPopover = (props: Props) => {
	const { className, isDesktop } = props;
	const anchorEl = useRef<HTMLElement>(null);
	const classes = useStyles(props);
	const { user } = useUserContext();
	const [isOpen, setIsOpen] = useState(false);
	const { data, loading } = Queries.useGetAddOnContentsQuery({
		variables: getAddonsQueryVariables(),
		skip: !(user?.company && user?.id)
	});

	const addons = data?.addOnContents.data || [];
	if (!user?.company) {
		return null;
	}

	const brand = Types.ProductBrand.Syrot;

	const openPopover = () => setIsOpen(true);
	const closePopover = () => setIsOpen(false);

	return (
		<Box className={classnames(classes.container, className)} onMouseLeave={closePopover}>
			<Box className={classes.iconContainer} ref={anchorEl} onMouseEnter={openPopover}>
				<BrandIconOnly brand={brand} className={classes.icon} color="secondary" />
			</Box>
			{isDesktop ? (
				<Popover
					anchorEl={anchorEl.current}
					open={isOpen}
					onClose={closePopover}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					transformOrigin={{ vertical: 'top', horizontal: 'right' }}
					classes={{
						root: classes.popoverRoot,
						paper: classes.popoverPaper
					}}
					disablePortal
				>
					<Box className={classes.innerPaper}>
						<AddonsPopoverContent
							brand={brand}
							loading={loading}
							addons={addons}
							isDesktop={isDesktop}
						/>
					</Box>
				</Popover>
			) : (
				<Dialog
					open={isOpen}
					onClose={closePopover}
					fullScreen
					classes={{ icon: classes.dialogCloseButton, paper: classes.dialogPaper }}
				>
					<DialogTitle className={classes.dialogTitle}>
						<Typography variant="h6" localeId="common.content-type.addons" />
					</DialogTitle>
					<DialogContent className={classes.dialogContent}>
						<AddonsPopoverContent
							brand={brand}
							loading={loading}
							addons={addons}
							isDesktop={isDesktop}
						/>
					</DialogContent>
				</Dialog>
			)}
		</Box>
	);
};

export default AddonsPopover;
