import React, { useState } from 'react';
import { Box, Container, Divider } from '@mui/material';
import { useIntl } from 'react-intl';
import { useUserContext } from '../../../contexts';
import { DrawerMenu, Avatar, Icon, Link, Logo, SearchBox, Typography } from 'components';
import type { HeaderProps } from '..';
import useStyles from './Header.mobile.styles';
import { headerLinks, useHeaderSearch, userSubMenuLinks } from '../Header.utils';
import ToolbarHeader from './ToolbarHeader';
import { createURL, getCountryCode } from '../../../utils';
import LocaleSwitch from '../LocaleSwitch';
import { UserCompanyLogo } from '../../UserCompanyLogo';
import { CATALOG_URLS } from '../../../urls';
import { AddonsPopover } from '../../HeaderUserNavBar/AddonsPopover';
import NextLink from '../../NextLink';
import { useFlags } from '../../..';

const Header = () => {
	const classes = useStyles();
	const { formatMessage, locale } = useIntl();
	const { onClear, onSearch, onSearchChange, search } = useHeaderSearch();
	const [isSearching, setIsSearching] = useState(search?.length && search.length > 0);

	const handleOpenSearch = () => setIsSearching(true);
	const handleCloseSearch = () => setIsSearching(false);
	const handleBlurSearch = () => {
		if (!search?.length) {
			handleCloseSearch();
		}
	};

	return (
		<Container classes={{ root: classes.container }} maxWidth="xl">
			{isSearching ? (
				<SearchBox
					placeholder={formatMessage({
						id: 'common.header.search-placeholder'
					})}
					onChange={onSearchChange}
					value={search || ''}
					onBlur={handleBlurSearch}
					onEnter={onSearch}
					onClear={onClear}
				/>
			) : (
				<>
					<Box className={classes.logo}>
						{/* This is a temporary fix, as CMED is not fully integrated to our platform and we need to redirect to drupal on click, the redirect is happening through ingress */}
						{locale === 'en-GB' ? (
							<Link href="/">
								<Logo locale={locale} />
							</Link>
						) : (
							<NextLink href="/">
								<Logo locale={locale} />
							</NextLink>
						)}
					</Box>
					<Box className={classes.iconsContainer}>
						<AddonsPopover
							classes={{ iconContainer: classes.addonIcon }}
							isDesktop={false}
						/>
						<Icon onClick={handleOpenSearch} icon="search" className={classes.icon} />
					</Box>
				</>
			)}
		</Container>
	);
};

export const HeaderMobile = (props: HeaderProps) => {
	const { drawerItems, drawerProps } = props;
	const { locale } = useIntl();
	const flags = useFlags();
	const countryCode = getCountryCode(locale);
	const classes = useStyles();
	const { user } = useUserContext();
	const [showChildMenu, setShowChildMenu] = useState(false);

	const { company } = user || {};
	const { name: companyName, logoUrl: companyLogo } = company || {};

	if (showChildMenu && user) {
		const homePageLink = createURL(CATALOG_URLS.base, 'catalog', true).url;
		return (
			<DrawerMenu
				classes={classes}
				linkComponent={NextLink}
				hideDrawerIconOnOpen
				closeOnClick
				toolbarContent={
					<div
						onClick={() => setShowChildMenu(false)}
						className={classes.subMenuHeaderContainer}
					>
						<Icon icon="caretLeft" className={classes.backIcon} />
						<Typography color="secondary" localeId="common.header.main-menu" />
					</div>
				}
				menuItemsHeader={
					<div className={classes.userDetails}>
						<Divider />
						<Avatar
							className={classes.avatar}
							email={user.email}
							firstName={user.firstName}
							lastName={user.lastName}
						/>
						<Typography className={classes.userFullName}>
							{user.firstName} {user.lastName}
						</Typography>
						<Divider className={classes.userDivider} />
						{company && (
							<>
								<Link
									display="flex"
									alignItems="center"
									my={2}
									px={3}
									href={homePageLink}
								>
									<UserCompanyLogo
										companyLogo={companyLogo}
										className={classes.companyLogo}
									/>
									<Typography title={companyName} />
								</Link>
								<Divider className={classes.userDivider} />
							</>
						)}
					</div>
				}
				items={userSubMenuLinks(locale)}
				header={<Header />}
				{...drawerProps}
			/>
		);
	}

	return (
		<DrawerMenu
			linkComponent={NextLink}
			classes={classes}
			closeOnClick
			items={[...headerLinks(countryCode, true, locale, flags), ...(drawerItems ?? [])]}
			toolbarContent={<ToolbarHeader onHeaderClick={() => setShowChildMenu(true)} />}
			hideDrawerIconOnOpen
			header={<Header />}
			menuItemsFooter={<LocaleSwitch />}
			{...drawerProps}
		/>
	);
};
