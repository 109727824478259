import React from 'react';
import { Dropdown, Icon } from 'components';
import useStyles from './LocaleSwitch.styles';
import { getLanguageFromLocale } from '../../../utils/locale';
import { useRouter } from '../../../hooks';
import { getCountryCode, isFeatureEnabled } from '../../../utils';

const supportedLocales = [
	{ url: '', code: 'de-CH' },
	{ url: '/fr-CH', code: 'fr-CH' }
];

const LocaleSwitch = () => {
	const classes = useStyles();
	const { locale, pathname } = useRouter();

	const isSwitchAvailable = isFeatureEnabled('languageSwitch', getCountryCode(locale));

	const changeLocale = (value: string | null) => {
		if (value === null) {
			return;
		}
		const newUrl = supportedLocales.find((x) => x.code === value)?.url + pathname;
		window?.location?.replace(newUrl);
	};

	if (!isSwitchAvailable) {
		return null;
	}

	return (
		<Dropdown
			data-testid="locale-switch"
			value={locale}
			startIcon={<Icon className={classes.dropdownIcon} icon="language" />}
			options={supportedLocales.map((localeCode) => ({
				localeId: localeCode.code,
				id: localeCode.code
			}))}
			minimal
			titleLocaleId={locale ? getLanguageFromLocale(locale) : ''}
			onChange={changeLocale}
			classes={{
				minimalText: classes.dropdownText,
				minimalTextField: classes.dropdown,
				inputBaseRoot: classes.inputBaseRoot
			}}
		/>
	);
};

export default LocaleSwitch;
