import React from 'react';
import classnames from 'classnames';
import { Popover } from '@mui/material';
import { Avatar, Typography, Link } from 'components';
import { useIntl } from 'react-intl';
import { UserPopoverProps } from './UserPopover.types';
import useStyles from './UserPopover.styles';
import { createURL, getCountryCode, isFeatureEnabled } from '../../../utils';
import { UserCompanyLink } from './UserCompanyLink';

const UserPopover = (props: UserPopoverProps) => {
	const { user, links, open, anchorEl, onClose, hasGroupMembership } = props;
	const classes = useStyles();
	const { locale, formatMessage } = useIntl();

	return (
		<Popover
			anchorEl={anchorEl}
			open={open}
			PaperProps={{ className: classes.popoverContainer }}
			onClose={onClose}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			transformOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<div className={classes.container}>
				<Link
					href={links.dashboard}
					className={classnames(classes.centered, classes.avatarContainer)}
				>
					<Avatar
						className={classes.avatar}
						email={user.email}
						firstName={user.firstName}
						lastName={user.lastName}
					/>
					<Typography
						title={`${user.firstName} ${user.lastName}`}
						fontFamily="Poppins"
						color="primary"
						className={classes.userNameTitle}
					/>
				</Link>
				<div className={classes.divider} />
				<UserCompanyLink
					user={user}
					hasGroupMembership={hasGroupMembership}
					links={links}
				/>
				<Link href={links.dashboard} className={classes.regularLink}>
					{formatMessage({ id: 'common.header.user-navbar.popover.my-cme' })}
				</Link>
				<Link href={links.profile} className={classes.regularLink}>
					{formatMessage({ id: 'common.header.user-navbar.popover.user-management' })}
				</Link>
				{isFeatureEnabled('memberships', getCountryCode(locale)) && (
					<Link
						href={createURL(links.dashboardMembership, 'catalog', true).url}
						className={classes.regularLink}
					>
						{formatMessage({ id: 'common.header.user-navbar.popover.membership' })}
					</Link>
				)}
				<div className={classes.divider} />
				<Link
					onClick={links.logout}
					className={classnames(classes.regularLink, classes.logOutLink)}
				>
					{formatMessage({ id: 'common.header.user-navbar.popover.logout' })}
				</Link>
			</div>
		</Popover>
	);
};

export default UserPopover;
