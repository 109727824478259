import React from 'react';
import MuiTabs, { TabsProps } from '@mui/material/Tabs';
import Tab, { TabClasses } from '@mui/material/Tab';
import makeStyles from '@mui/styles/makeStyles';
import { ThemeTypes, Typography } from '../';
import { ClassNameMap } from '@mui/styles/withStyles';

type Tab<T extends string = string> = { key: T; title?: string; localeId?: string };

export type Props<T extends string> = Omit<TabsProps, 'onChange'> & {
	tabs: Array<Tab<T>>;
	onChange?: (newTab: T) => void;
	selectedTab?: T | null;
	classes?: ClassNameMap;
	'data-test'?: string;
	tabClasses?: Partial<TabClasses>;
};

const useStyles = makeStyles((theme: ThemeTypes.Theme) => ({
	indicator: {
		backgroundColor: theme.palette.secondary[400]
	}
}));

const Tabs = <T extends string>(props: Props<T>) => {
	const {
		tabs,
		selectedTab,
		onChange,
		classes: classesProp,
		tabClasses,
		'data-test': dataTest,
		...others
	} = props;
	const classes = useStyles({ classes: classesProp });
	const handleChange = (newTab: T) => {
		if (onChange) {
			onChange(newTab);
		}
	};

	return (
		<MuiTabs
			value={selectedTab}
			onChange={(_, newTab) => handleChange(newTab)}
			classes={classes}
			data-test={dataTest}
			{...others}
		>
			{tabs.map((tab) => (
				<Tab
					key={`${tab.key}`}
					value={tab.key}
					label={<Typography title={tab.title} localeId={tab.localeId} />}
					data-testid={`${dataTest}_${tab.key}`}
					data-test-active={selectedTab === tab.key}
					classes={tabClasses}
				/>
			))}
		</MuiTabs>
	);
};

export default Tabs;
