import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		dropdownText: {
			display: 'flex',
			fontWeight: 700,
			color: theme.palette.primary.main,
			alignItems: 'center',
			opacity: 1,
			marginTop: 2
		},
		dropdownIcon: {
			marginRight: theme.spacing(0.5)
		},
		root: {
			margin: theme.spacing('auto', 'auto', 2, 2),
			[theme.breakpoints.up('sm')]: {
				margin: theme.spacing(0, 0, 0, 'auto')
			}
		},
		dropdown: {
			paddingRight: 0
		},
		inputBaseRoot: {
			minWidth: 'unset'
		}
	}),
	{ name: 'LocaleSwitch', index: 100 }
);

export default useStyles;
