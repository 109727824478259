import { commonTracking, COUNTRY_CODE_3_TO_DOMAIN, env, QueryParamsType } from '.';
import { getCountryCode3, getUrlWithParams } from './utils';

/**
 * Creates function that redirects user to page path with query parameters.
 * @param path
 */
const withQueryParams = (path: string) => (query: QueryParamsType) =>
	getUrlWithParams(path, query, false);

export const USER_URLS = {
	base: '/user/',
	registration: withQueryParams('user/registration'),
	login: '/user/login',
	loginWithQuery: withQueryParams('user/login'),
	logout:
		({ trackLogout = true, locale }: { locale: Locale; trackLogout?: boolean }) =>
		async () => {
			const countryCode = getCountryCode3(locale);
			const language = COUNTRY_CODE_3_TO_DOMAIN[countryCode] && locale;

			const params = new URLSearchParams();
			countryCode && params.set('cr', countryCode);
			language && params.set('lang', language);
			const logoutLink = `${env.USER_SERVICE_BASE_URL()}/logout?${params.toString()}`;

			if (trackLogout) {
				await commonTracking.resetUser();
			}

			window.location.href = logoutLink;
		},
	forgotPassword: withQueryParams('user/forgot-password'),
	dashboard: `/user/dashboard/bookings`,
	bookingDetails: '/user/dashboard/bookings/[id]/booking-details',
	bookingBills: '/user/dashboard/bookings/[id]/invoice-receipt',
	bookingCertificates: '/user/dashboard/bookings/[id]/certificates',
	bookingDownloads: '/user/dashboard/bookings/[id]/downloads',
	eventLocation: '/user/dashboard/bookings/[id]/location',
	eventProgram: '/user/dashboard/bookings/[id]/program',
	dashboardMembership: '/user/dashboard/membership',
	completeProfile: withQueryParams('user/complete-profile'),
	resetPassword: withQueryParams('user/reset-password'),
	profile: '/user/dashboard/profile',
	addons: '/user/dashboard/addons'
};

export const MEDIATHEK_URLS = {
	search: '/mediathek/search',
	topics: '/mediathek/topics',
	summedup: '/mediathek/summedup',
	webup: '/mediathek/webup',
	content: '/mediathek/content/[id]/on-demand',
	skills: '/mediathek/skills',
	guidelines: '/mediathek/guidelines'
};

export const CATALOG_URLS = {
	base: '/',
	faq: '/faq',
	contact: '/contact',
	impresum: '/impressum',
	livestream: '/livestream',
	content: '/content/[id]',
	onDemandLanding: '/on-demand',
	onDemandContent: '/on-demand/[id]',
	product: '/product/[code]',
	partner: '/partner-fortbildungsforum/[companyIdOrSlug]/[departmentIdOrSlug]',
	partnerVideo:
		'/partner-fortbildungsforum/[companyIdOrSlug]/[departmentIdOrSlug]/videos/[contentId]',
	partners: '/partner-fortbildungsforum',
	onDemandAccreditationCourse: '/ondemand-kurs/[id]',
	syrot: '/syrot/[id]',
	syfatLanding: '/facharztvorbereitungskurs'
};

export const BOOKING_URLS = {
	event: '/booking/events/[id]',
	memberships: '/booking/memberships',
	membership: '/booking/memberships/[id]',
	manualTrialTransitionSuccess: '/booking/manual-trial-transition/success'
};
