import React from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import useStyles from './Icon.styles';
import { IconProps } from './Icon.types';
import * as icons from './icons';

const Icon = (props: IconProps) => {
	const {
		icon,
		className,
		onClick,
		strokeWidth = 2,
		rounded = false,
		style,
		[`data-testid`]: testId
	} = props;
	const IconComponent = icons[icon];
	const classes = useStyles();
	return (
		<Box
			className={classNames(classes.icon, { [classes.rounded]: rounded }, className)}
			onClick={onClick}
			style={{ ...style, strokeWidth }}
		>
			<IconComponent data-testid={testId} />
		</Box>
	);
};

export default Icon;
