import React from 'react';
import { Box } from '@mui/material';
import { useUserContext } from '../../../contexts';
import { useChangeURL } from '../../../hooks';
import setStateInCookies from '../../../cookies/setStateInCookies';
import * as hydra from '../../../hydra/hydra';
import { Avatar, Icon, Typography } from 'components';
import { useToolbarHeaderStyles } from './Header.mobile.styles';
import { useIntl } from 'react-intl';

const ToolbarHeader = ({ onHeaderClick }: { onHeaderClick: () => void }) => {
	const userCtx = useUserContext();
	const { isLoggedIn, user } = userCtx;
	const { locale } = useIntl();
	const classes = useToolbarHeaderStyles();

	const changeURL = useChangeURL();

	const handleLogin = () => {
		const nonce = setStateInCookies({});
		changeURL(hydra.getAuthURL({ nonce, locale }));
	};

	return isLoggedIn && (user?.firstName || user?.email) ? (
		<div className={classes.container} onClick={onHeaderClick}>
			<Avatar
				className={classes.avatar}
				email={user?.email}
				firstName={user?.firstName}
				lastName={user?.lastName}
			/>
			<div className={classes.rightContainer}>
				<Typography variant="textXSmall" className={classes.subtitle}>
					{user.firstName ?? ''} {user.lastName ?? ''}
				</Typography>
				<Box display="flex">
					<Typography
						variant="textMedium"
						className={classes.title}
						localeId="common.header.account"
					/>
					<Icon icon="caretRight" className={classes.caretRight} />
				</Box>
			</div>
		</div>
	) : (
		<div className={classes.container} onClick={handleLogin}>
			<Icon icon="login" className={classes.avatar} />
			<div className={classes.rightContainer}>
				<Typography
					variant="textXSmall"
					className={classes.subtitle}
					localeId="common.header.user-account"
				/>
				<div className={classes.signIn}>
					<Typography
						variant="textMedium"
						className={classes.title}
						localeId="common.header.login-sign-up"
					/>
					<Icon icon="caretRight" className={classes.signInIcon} />
				</div>
			</div>
		</div>
	);
};

export default ToolbarHeader;
