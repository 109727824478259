import makeStyles from '@mui/styles/makeStyles';

import { ThemeTypes } from '..';

const useStyles = makeStyles<ThemeTypes.Theme, { height: number }>((theme) => ({
	links: ({ height }) => ({
		display: 'flex',
		height: height,
		paddingTop: theme.spacing(1),
		backgroundColor: theme.palette.common.white
	}),
	link: {
		display: 'flex',
		padding: theme.spacing(0, 1.5),
		height: '100%',
		alignItems: 'center',
		'& > p': {
			fontWeight: 400
		},
		'& .active > p': {
			fontWeight: 600
		}
	},
	tab: ({ height }) => ({
		minHeight: height - 8,
		padding: 0,
		marginRight: 0,
		paddingRight: theme.spacing(1),
		...theme.border({ kind: 'bottom', color: '#00000014', width: 1, radius: 0 })
	})
}));

export default useStyles;
