import { Types } from '../index';

export const pricePerCycle = [
	{ id: Types.MembershipCycle.OneYear, localeId: 'common.membership-card.per-year' },
	{ id: Types.MembershipCycle.SixMonths, localeId: 'common.membership-card.per-six-months' },
	{ id: Types.MembershipCycle.OneMonth, localeId: 'common.membership-card.per-one-month' },
	{ id: Types.MembershipCycle.TwoMonths, localeId: 'common.membership-card.per-two-months' },
	{ id: Types.MembershipCycle.ThreeMonths, localeId: 'common.membership-card.per-three-months' }
];

export const cyclePricePeriod = [
	{ id: Types.MembershipCycle.OneYear, localeId: 'common.membership-card.per-year' },
	{ id: Types.MembershipCycle.SixMonths, localeId: 'common.membership-card.per-six-months' },
	{ id: Types.MembershipCycle.OneMonth, localeId: 'common.membership-card.for-one-month' },
	{ id: Types.MembershipCycle.TwoMonths, localeId: 'common.membership-card.for-two-months' },
	{ id: Types.MembershipCycle.ThreeMonths, localeId: 'common.membership-card.for-three-months' }
];
