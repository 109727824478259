import React, { useState } from 'react';

import noop from '../utils/noop';
import { useTextFieldStyles } from './SearchBox.styles';
import { TextField } from '../TextField';
import type { SearchBoxProps } from './SearchBox.types';

const SearchBox = (props: SearchBoxProps) => {
	const {
		placeholder,
		value = '',
		onClear = noop,
		onEnter,
		classes: parentClasses,
		...others
	} = props;
	const [isFocused, setIsFocused] = useState(false);
	const { iconFocused, ...classes } = useTextFieldStyles(props);
	const handleToggleFocus = () => {
		if ((isFocused && !value) || !isFocused) {
			setIsFocused(!isFocused);
		}
	};

	const handleClear = () => {
		setIsFocused(false);
		onClear();
	};

	const onKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
		if (onEnter && event.key === 'Enter') {
			onEnter();
		}
	};

	return (
		<TextField
			classes={{
				...classes,
				startIcon: isFocused ? iconFocused : classes.icon,
				...parentClasses
			}}
			icon="search"
			hideClearIcon={value.length === 0}
			value={value}
			onFocusCapture={handleToggleFocus}
			onBlurCapture={handleToggleFocus}
			onClear={handleClear}
			onKeyPress={onKeyPress}
			InputProps={{
				classes: {
					input: classes.input
				},
				placeholder
			}}
			{...others}
		/>
	);
};

export default SearchBox;
