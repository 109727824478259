import React from 'react';
import { AddonCardContentProps } from './AddonCardContent.types';
import useStyles from './AddonCardContent.styles';
import { Box } from '@mui/material';
import { Typography } from 'components';
import {
	ContentDuration,
	getAddonCatalogLink,
	ScheduleReplacementNotification,
	Thumbnail
} from '../../';
import classnames from 'classnames';

const AddonCardContent = (props: AddonCardContentProps) => {
	const {
		brand,
		contentType,
		specialityIconUrl,
		completed,
		endDateTime,
		replacementDate,
		thumbnailUrl,
		contentId,
		title,
		subtitle,
		duration,
		time,
		videoId,
		className
	} = props;
	const classes = useStyles(props);

	const catalogContentLink = getAddonCatalogLink(contentId, videoId);

	return (
		<a href={catalogContentLink} className={classnames(classes.container, className)}>
			<Box className={classes.thumbnailContainer}>
				<Thumbnail
					brand={brand}
					contentId={contentId}
					duration={duration}
					contentType={contentType}
					title={title}
					thumbnailUrl={thumbnailUrl}
					specialityIconUrl={specialityIconUrl}
					showDuration={false}
					freeBadgeProps={{
						variant: 'white'
					}}
				/>
			</Box>
			<Box display="flex" flexDirection="column" justifyContent="space-between">
				<Box>
					<Typography variant="textXSmall" title={subtitle} mb={0.5} color="primary" />
					<Typography variant="h6" title={title} color="primary" />
					<ContentDuration duration={duration} time={time} />
				</Box>
				<ScheduleReplacementNotification
					completed={completed}
					endDateTime={endDateTime}
					replacementDate={replacementDate}
					time={time}
					className={classes.notification}
				/>
			</Box>
		</a>
	);
};

export default AddonCardContent;
