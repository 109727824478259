import React, { useState } from 'react';
import classnames from 'classnames';
import { Avatar, Button, Link, Typography } from 'components';
import { CATALOG_URLS, USER_URLS, useHydrationContext, getAccreditationItem } from '../..';
import { HeaderUserNavBarProps } from './HeaderUserNavBar.types';
import useStyles from './HeaderUserNavBar.styles';
import UserPopover from './UserPopover/UserPopover';
import { useIntl } from 'react-intl';
import { AddonsPopover } from './AddonsPopover';

type CreditsBadgeProps = {
	total: number;
};
const CreditsBadge = ({ total }: CreditsBadgeProps) => {
	const classes = useStyles();

	return (
		<div className={classes.accreditationPointsContainer}>
			<Typography
				localeId="common.header.credits"
				localeValues={{
					amount: total
				}}
				color="primary"
				fontFamily="Poppins"
				variant="body1"
				className={classes.accreditationPoints}
			/>
		</div>
	);
};

const HeaderUserNavBar = (props: HeaderUserNavBarProps) => {
	const { isLoading, isLoggedIn, user, userMembership } = props;
	const { accreditationPoints } = userMembership || {};
	const { isHydrated } = useHydrationContext();
	const { locale } = useIntl();
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const totalPointsCertifiedItem =
		user && userMembership
			? getAccreditationItem(user.totalPointCertified, userMembership.membership.country)
			: undefined;
	const total =
		totalPointsCertifiedItem?.all.reduce((acc, item) => acc + (item.amount || 0), 0) || 0;

	const hasGroupMembership = Boolean(userMembership?.addedToGroupMembershipDateTime);

	const links = {
		dashboard: USER_URLS.dashboard,
		homePage: CATALOG_URLS.base,
		registration: USER_URLS.registration({}),
		logout: USER_URLS.logout({ locale }),
		profile: USER_URLS.profile,
		dashboardMembership: USER_URLS.dashboardMembership
	};

	// Open the UserPopover menu
	const handleAvatarClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
		setAnchorEl(e.currentTarget);
	};

	return (
		<div className={classes.container}>
			{userMembership && <CreditsBadge total={total} />}
			{!isLoading && !isLoggedIn && isHydrated && (
				<Button
					href={USER_URLS.login}
					localeId="common.login.title"
					variant="text"
					typographyProps={{ className: classes.buttonText }}
					uppercase={false}
				/>
			)}
			{hasGroupMembership && <AddonsPopover isDesktop className={classes.addonsContainer} />}
			{isLoggedIn && (user?.firstName || user?.email) ? (
				<div onClick={handleAvatarClick} data-test="common/header-avatar">
					<Avatar
						className={classnames(classes.avatar, {
							[classes.avatarWithoutMembership]: !accreditationPoints
						})}
						email={user.email}
						firstName={user.firstName}
						lastName={user?.lastName}
					/>
				</div>
			) : (
				!isLoading &&
				isHydrated && (
					<Button
						component={Link}
						href={isLoggedIn ? links.dashboard : links.registration}
						className={classes.primaryButton}
						typographyProps={{ className: classes.buttonText }}
						size="large"
						variant="outlined"
						localeId="common.registration.primary-action"
					/>
				)
			)}
			{user && (
				<UserPopover
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					links={links}
					user={user}
					onClose={() => setAnchorEl(null)}
					hasGroupMembership={hasGroupMembership}
				/>
			)}
		</div>
	);
};

export default HeaderUserNavBar;
