import React from 'react';

import { Box, ListItemButton } from '@mui/material';

import { Badge, Icon, parseDBDate, Typography } from 'components';

import useStyles from './ContentPlaylistSyrotItem.styles';
import {
	Types,
	Speakers,
	SpeakerVariant,
	buildURLWithParams,
	MEDIATHEK_URLS,
	createURL,
	getScheduleReplacementNotificationType
} from '../../';
import { ContentPlaylistSyrotItemProps } from './ContentPlaylistSyrotItem.types';
import classnames from 'classnames';
import ScheduleReplacementNotification from '../ScheduleReplacementNotification/ScheduleReplacementNotification';
import { ContentDuration } from '../ContentDuration';

const ContentPlaylistSyrotItem = (props: ContentPlaylistSyrotItemProps) => {
	const {
		id,
		speakers,
		completed,
		endDateTime,
		title,
		contentId,
		contentType,
		time,
		duration,
		replacementDate,
		subContentId,
		disabled,
		selectedLecture,
		onSelect,
		schedule
	} = props;
	const classes = useStyles();

	const replacementNotificationType = getScheduleReplacementNotificationType({
		completed,
		endDateTime,
		replacementDate: replacementDate ? parseDBDate(replacementDate) : null,
		time
	});

	const isPdf = contentType === Types.ContentType.Pdf;

	const contentLink = buildURLWithParams(MEDIATHEK_URLS.content, [contentId || '']);
	const contentLinkWithQuery = subContentId ? `${contentLink}/${subContentId}` : contentLink;
	const fullContentLink = createURL(contentLinkWithQuery, 'catalog', true).url;
	const clickProps = onSelect
		? {
				onClick: () => {
					if (!disabled && selectedLecture?.id !== id) {
						onSelect(schedule);
					}
				}
			}
		: {
				href: fullContentLink
			};

	return (
		<ListItemButton
			{...clickProps}
			className={classnames(classes.container, {
				[classes.selectedItem]: id === selectedLecture?.id,
				[classes.disabledItem]: disabled
			})}
		>
			<Box className={classes.checkboxContainer}>
				{completed ? (
					<Icon
						className={classnames(classes.checkedIcon, {
							[classes.mutedCheckedIcon]: !!replacementNotificationType
						})}
						icon="success"
					/>
				) : (
					<Icon className={classes.icon} icon="circle" />
				)}
			</Box>
			<Box className={classes.contentContainer}>
				<Typography title={title} className={classes.title} />
				{!isPdf && <ContentDuration time={time} duration={duration} />}
				{isPdf && <Badge kind="info" title="PDF" />}
				<Speakers
					speakers={speakers}
					variant={SpeakerVariant.BRIEF}
					classes={{
						speakerText: classes.speakerName
					}}
				/>
				<ScheduleReplacementNotification
					completed={completed}
					endDateTime={endDateTime}
					replacementDate={replacementDate}
					time={time}
				/>
			</Box>
		</ListItemButton>
	);
};

export default ContentPlaylistSyrotItem;
