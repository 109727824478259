import React from 'react';
import { ScheduleReplacementNotificationProps } from './ScheduleReplacementNotification.types';
import useStyles from './ScheduleReplacementNotification.styles';
import { DateFormat, Icon, parseDBDate, Typography, UncontrolledPopover } from 'components';
import { Box } from '@mui/material';
import { formatDate } from '../../formatters';
import { getScheduleReplacementNotificationType } from '../utils';
import { ReplacementNotificationType } from '../types';

const getNotificationContent = (
	replacementNotificationType: ReplacementNotificationType | null
) => {
	let notificationTitle = '';
	let notificationTitleColor = '';
	let notificationDescription = '';

	switch (replacementNotificationType) {
		case 'completed-and-replaced':
			notificationTitle = 'common.syrot-content-item.completed-and-replaced.title';
			notificationDescription =
				'common.syrot-content-item.completed-and-replaced.description';
			notificationTitleColor = '#8E24AA';
			break;
		case 'started-not-completed-and-to-be-replaced':
			notificationTitle =
				'common.syrot-content-item.started-not-completed-and-to-be-replaced.title';
			notificationDescription =
				'common.syrot-content-item.started-not-completed-and-to-be-replaced.description';
			notificationTitleColor = '#CF701A';
			break;
		case 'not-started-and-to-be-replaced':
			notificationTitle = 'common.syrot-content-item.not-started-and-to-be-replaced.title';
			notificationDescription =
				'common.syrot-content-item.not-started-and-to-be-replaced.description';
			notificationTitleColor = '#CF701A';
			break;
		case 'expired-and-not-replaced':
			notificationTitle = 'common.syrot-content-item.expired-and-not-replaced.title';
			notificationDescription =
				'common.syrot-content-item.expired-and-not-replaced.description';
			notificationTitleColor = '#8E24AA';
			break;
	}

	return {
		notificationTitle,
		notificationTitleColor,
		notificationDescription
	};
};

const ScheduleReplacementNotification = (props: ScheduleReplacementNotificationProps) => {
	const {
		className,
		withIcon,
		completed,
		endDateTime,
		replacementDate: replacementDateString,
		time
	} = props;
	const classes = useStyles(props);

	const replacementDate = replacementDateString
		? parseDBDate(replacementDateString, DateFormat.dbShort)
		: null;

	const replacementNotificationType = getScheduleReplacementNotificationType({
		completed,
		endDateTime,
		replacementDate,
		time
	});

	const replacementDateFormatted = formatDate(replacementDate, DateFormat.date);

	const dayBeforeReplacementDate = replacementDate?.subtract(1, 'day');
	const dayBeforeReplacementDateFormatted = dayBeforeReplacementDate
		? formatDate(dayBeforeReplacementDate, DateFormat.date)
		: null;

	const { notificationDescription, notificationTitle, notificationTitleColor } =
		getNotificationContent(replacementNotificationType);

	if (!replacementNotificationType) {
		return null;
	}

	return (
		<Box display="flex" alignItems="center" gap={0.5} className={className}>
			{withIcon && (
				<Icon
					icon="warningTriangle"
					className={classes.warningTriangle}
					style={{ color: notificationTitleColor }}
				/>
			)}
			<Typography
				localeId={notificationTitle}
				localeValues={{
					replacementDate: replacementDateFormatted,
					dayBeforeReplacementDate: dayBeforeReplacementDateFormatted
				}}
				variant="caption"
				style={{ color: notificationTitleColor }}
			/>
			<UncontrolledPopover
				target={<Icon icon="info" className={classes.infoIcon} />}
				classes={{ content: classes.notificationPopover }}
				openOnHover
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Typography localeId={notificationDescription} variant="caption" />
			</UncontrolledPopover>
		</Box>
	);
};

export default ScheduleReplacementNotification;
