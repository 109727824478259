import getCurrentUrlWithoutQuery from './getCurrentUrlWithoutQuery';
import { enrichWithUTMParameters } from './utmParameters';

/**
 * @param replacementQuery - query params string without initial `?`
 */
const removeQueryParams = (replacementQuery?: string) => {
	if (typeof window === 'undefined') {
		console.error('removeQueryParams should not be used on server-side');
		return;
	}
	const currentUrlWithoutQuery = getCurrentUrlWithoutQuery(replacementQuery);
	const urlWithUTMParameters = enrichWithUTMParameters(
		currentUrlWithoutQuery,
		window.location.search
	);
	window.history.replaceState(undefined, '', urlWithUTMParameters);
};

export default removeQueryParams;
